/* eslint-disable camelcase */
import { Moment } from 'moment';
import { Moment as MomentTz } from 'moment-timezone';
import deepUpdate from '../../../services/deepUpdate';
import { types as loginTypes, LoginActiontypes } from '../account/login.actions';
import { types as quoteTypes, QuoteActions } from './actions';
import { types as saveTypes, SaveActions } from './save.actions';
import { types as userTypes, UserActions } from './user.actions';

import {
  types as licenceTypes,
  LicenceActions,
  HandleSaveFailuresActionTypes,
} from './licence.actions';

export enum LicenceTypes {
  uk_prov = 'uk_prov',
  uk_manual = 'uk_manual',
  uk_auto = 'uk_auto',
  non_uk = 'non_uk',
}

const urlLicenceType =
  window.location.pathname.split('/')[1] === 'learner-driver'
    ? LicenceTypes.uk_prov
    : LicenceTypes.uk_manual;

export interface LicenceState {
  type: LicenceTypes;
  country: string;
  licenceNumber: string;
  cassieType: string;
  fullLicenceConfirmed: boolean | null;
  fullLicenceDate: Moment | MomentTz | null;
  upgradeFromStart: boolean;
  hasUpdatedLicenceType: boolean;
}

export const initialState: LicenceState = {
  type: urlLicenceType,
  country: '',
  licenceNumber: '',
  cassieType: '',
  fullLicenceConfirmed: null,
  fullLicenceDate: null,
  upgradeFromStart: false,
  hasUpdatedLicenceType: false,
};

const handleSaveFailure = (action: HandleSaveFailuresActionTypes, state: LicenceState) => {
  if (!action.error) return state;

  switch (action.error.code || (action.error.licence_number && action.error.licence_number.code)) {
    case 'LICENCENOTFOUND':
      return deepUpdate(state, 'cassieType', 'N');
    case 'LICENCEEXPIRED':
      return deepUpdate(state, 'cassieType', 'E');
    case 'UNCONFIRMEDPROVISIONAL':
      return deepUpdate(state, 'cassieType', 'P');
    case 'EXISTINGDETAILSNLIC':
    case 'EXISTINGDETAILSLIC':
      return {
        ...state,
        licenceNumber: '',
      };
    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LicenceActionTypes =
  | LicenceActions
  | UserActions
  | QuoteActions
  | SaveActions
  | LoginActiontypes;

export default function licence(
  state: LicenceState = initialState,
  action = {} as LicenceActionTypes,
): LicenceState {
  switch (action.type) {
    case licenceTypes.SET_LICENCE_FIELD:
      return deepUpdate(state, action.field, action.value);
    case licenceTypes.CHANGE_LICENCE_TYPE:
      return { ...state, type: action.value };
    case loginTypes.LOGOUT_SUCCESS:
    case quoteTypes.RESET_QUOTE:
    case userTypes.PREFILL_OWNER:
      // never reset the licence type as this informs on the type of customer
      return { ...initialState, type: state.type };
    case saveTypes.SAVE_QUOTE_SUCCESS:
      return {
        ...state,
        cassieType: action.data.cassie_licence_type,
      };
    case saveTypes.SAVE_QUOTE_FAILURE:
      return handleSaveFailure(action, state);
    case userTypes.PREFILL_DRIVER:
    case userTypes.PREFILL_DRIVER_ON_EDIT:
    case userTypes.PREFILL_CONNECTION:
      return {
        ...state,
        country: action.data.customer.licence.country,
        licenceNumber: action.data.customer.licence.number,
        fullLicenceConfirmed: action.data.customer.licence.fullLicenceConfirmed,
        fullLicenceDate: action.data.customer.licence.fullLicenceDate,
        // only set type if the customer has not switched it in the flow
        type: !state.hasUpdatedLicenceType ? action.data.customer.licence.type : state.type,
      };
    case licenceTypes.UPDATE_LICENCE:
      return {
        ...state,
        type: action.licenceType,
        hasUpdatedLicenceType: true,
      };
    default:
      return state;
  }
}

/* eslint-disable custom-rules/id-formats */
import React, { useState } from 'react';

import styled from 'styled-components';

import { PageModal, Panel, Button, P, MediaQuery } from '@rentecarlo/component-library';

import { ComponentType, useComponentId, useOptimizelyTrack } from '@hooks';

import PersonWithCar from './assets/person-with-car.png';
import UpgradeLicence from './assets/upgrade-licence.png';
import CalendarMonth from './assets/calendar-month.png';

import Line from './assets/line.png';

import StepCard from './StepCard';
import LearnMore from './LearnMore';

const AVERAGE_NEWDRIVER_PRICE = 178;

const Content = styled.div`
  width: 100%;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.textPrimary};
  font-family: ${({ theme }) => theme.title};
  font-size: 32px;
  font-weight: bold;
  line-height: 38px;
  margin-bottom: 16px;
`;

const Container = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DottedLine = styled.img.attrs({ src: Line })`
  width: 2px;
  margin: 4px 0;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

interface PriceProps {
  primary?: boolean;
}

const Price = styled.div<PriceProps>`
  font-family: ${({ theme }) => theme.title};
  color: ${({ primary, theme }) => (primary ? theme.successSecondary : theme.title)};
  font-size: 32px;
  font-weight: 700;

  padding-bottom: 6px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  margin: 16px 0;
`;

const SubTitle = styled.h3`
  color: ${({ theme }) => theme.textPrimary};
  font-family: ${({ theme }) => theme.title};
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  margin: 16px;

  text-align: center;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.textPrimary};
  font-family: ${({ theme }) => theme.standard};
  font-size: 16px;
  line-height: 23px;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const TextContainer = styled.div`
  margin: 16px 0 64px;

  ${MediaQuery.mobile`
    flex-grow: 1;
  `}
`;

interface Props {
  show: boolean;
  onClose: () => void;
  price: number;
}

const LearnerToNewDriverModal: React.FC<Props> = ({ show, onClose, price }) => {
  const creator = useComponentId();
  const sendEvent = useOptimizelyTrack();

  const [confirmSelected, setConfirmSelected] = useState(false);

  const displayPrice = price.toFixed(2);
  const displayNewDriverPrice = AVERAGE_NEWDRIVER_PRICE.toFixed(2);
  const displayPriceDifference = (AVERAGE_NEWDRIVER_PRICE - price).toFixed(2);
  const displaySavingPercentage = Math.round((price / AVERAGE_NEWDRIVER_PRICE) * 100);

  return (
    <PageModal show={show}>
      <Content>
        {!confirmSelected ? (
          <>
            <Title id={creator(ComponentType.TITLE, 'learnerToNewDriverModal')}>
              Save Big Once You've Passed Your Driving Test!
            </Title>
            <Panel>
              <span id={creator(ComponentType.PARAGRAPH, 'learnerToNewDriverModal')}>
                Secure your discounted insurance price and hit the road confidently after passing!
              </span>
            </Panel>
            <Container>
              <StepCard
                id={creator(ComponentType.CONTAINER, 'learnerToNewDriverModal-upgradeLicence')}
                image={UpgradeLicence}
                altText='passed your test'
                text="Notify us once you've passed your driving test!"
              />
              <DottedLine />
              <StepCard
                id={creator(ComponentType.CONTAINER, 'learnerToNewDriverModal-calendarMonth')}
                image={CalendarMonth}
                altText='calendar month'
                text={`Get your first month on New Driver insurance for just £${displayPrice}`}
                bannerText={`Save £${displayPriceDifference} in your first month`}
              >
                <PriceContainer>
                  <Price id={creator(ComponentType.TEXT, 'learnerToNewDriverModal-price')} primary>
                    £{displayPrice}
                  </Price>
                  <P
                    id={creator(ComponentType.TEXT, 'learnerToNewDriverModal-firstMonth')}
                    fontSize={18}
                  >
                    first month
                  </P>
                </PriceContainer>
              </StepCard>
              <DottedLine />
              <StepCard
                id={creator(ComponentType.CONTAINER, 'learnerToNewDriverModal-personWithCar')}
                image={PersonWithCar}
                altText='person with car'
                text='Continue driving with New Driver insurance'
              >
                <BoldText id={creator(ComponentType.SUBTITLE, 'learnerToNewDriverModal')}>
                  After your first month
                </BoldText>
                <PriceContainer>
                  <Price id={creator(ComponentType.TEXT, 'learnerToNewDriverModal-newDriverPrice')}>
                    £{displayNewDriverPrice}
                  </Price>
                  <P
                    id={creator(ComponentType.TEXT, 'learnerToNewDriverModal-month')}
                    fontSize={18}
                  >
                    per month
                  </P>
                </PriceContainer>
              </StepCard>
              <SubTitle id={creator(ComponentType.SUBTITLE, 'learnerToNewDriverModal-info')}>
                Lock your New Driver price: No changes until you pass.
              </SubTitle>
              <LearnMore
                savingNumber={displayPriceDifference}
                savingPercentage={displaySavingPercentage.toString()}
              />
            </Container>
            <ButtonContainer>
              <Button
                id={creator(ComponentType.BUTTON, 'learnerToNewDriverModalConfirm')}
                onClick={() => {
                  sendEvent('learner_to_newdriver_confirm');
                  setConfirmSelected(true);
                }}
              >
                Secure Discount Now
              </Button>
              <Button
                id={creator(ComponentType.BUTTON, 'learnerToNewDriverModalSkip')}
                onClick={() => {
                  sendEvent('learner_to_newdriver_skip');
                  onClose();
                }}
                backgroundColor='transparent'
                borderColor='#171717'
                textColor='#171717'
                textHoverColor='#fff'
              >
                Skip
              </Button>
            </ButtonContainer>
          </>
        ) : (
          <>
            <Title id={creator(ComponentType.TITLE, 'learnerToNewDriverModal-confirm')}>
              Thank you for your interest!
            </Title>
            <TextContainer>
              <Text id={creator(ComponentType.TEXT, 'learnerToNewDriverModal-confirm')}>
                Thank you for your interest in our "Cover When You Pass" feature! <br />
                <br /> While{' '}
                <b id={creator(ComponentType.TEXT, 'learnerToNewDriverModal-confirm-bold')}>
                  this feature isn’t available just yet
                </b>
                , we’re working hard to bring it to you as soon as possible. <br />
                <br />
                Good luck with your driving practice!
              </Text>
            </TextContainer>
            <ButtonContainer>
              <Button
                id={creator(ComponentType.BUTTON, 'learnerToNewDriverModalInterestConfirm')}
                onClick={onClose}
              >
                I understand
              </Button>
            </ButtonContainer>
          </>
        )}
      </Content>
    </PageModal>
  );
};

export default LearnerToNewDriverModal;

import React, { useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import QuoteSummaryDetail from '@atoms/blocks/QuoteSummaryDetail';
import discount from '@services/discount';
import { capitaliseFirstLetter } from '@services/formatString';
import { useOptimizelyFlag, useOptimizelyFlagVariation } from '@hooks';
import { DispatchProps, StateProps } from './QuoteSummarySidebar.container';

import {
  Container,
  PriceLabel,
  PriceValue,
  QuoteSummaryDetailsContainer,
  Title,
  TitleWrapper,
  PriceContainer,
  PriceLabelWrapper,
  PriceValueWrapper,
  HistoricPriceWrapper,
  HistoricPriceValue,
  NumberPlate,
  LightParagraph,
} from './styles';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

interface OwnProps {
  fullscreen: boolean;
  goToOwnerDetails: () => void;
  showPrice: boolean;
  displayMobileSummary: boolean;
}

interface ComponentProps extends StateProps, DispatchProps, OwnProps {}

export const formatDuration = (duration: Dayjs): string => {
  if (!duration) return '';
  const time = dayjs.tz(duration, 'Europe/London');
  return `${time.format('ddd, Do MMM').toString()} at ${time.format('HH:mm').toString()}`;
};

const QuoteSummarySidebar: React.FC<ComponentProps> = (props) => {
  const {
    duration,
    pathname,
    car,
    carIsSelected,
    goToCar,
    goToDuration,
    displayDriverName,
    driver,
    goToAboutTheDriver,
    loggedIn,
    displayOwnerName,
    productType,
    owner,
    goToOwnerDetails,
    price,
    showPrice,
    initialPrice,
    isFinalPrice,
    crossSaleInitialPrice,
    isSubscription,
  } = props;

  const durationStartDate = formatDuration(duration.startDateTime);

  const durationEnd = useMemo(() => {
    return duration.endDateTime;
  }, [duration.endDateTime]);

  const durationEndDate = durationEnd && `To: ${formatDuration(durationEnd)}`;

  const hideSubsIndicativePrice = useOptimizelyFlag('TEMP_HIDE_INDICATIVE_SUBS').enabled;
  const indicativePriceRemoved = useOptimizelyFlagVariation('durationEnabled');

  const getDisplays = () => {
    if (indicativePriceRemoved) {
      return {
        displayPrice:
          isFinalPrice &&
          [
            '/product-choice',
            '/important-information',
            '/final-quote',
            '/quote-register',
            '/almost-there',
            '/payment',
          ].includes(pathname),
        displayDuration: [
          '/duration',
          '/duration-of-cover',
          '/product-choice',
          '/important-information',
          '/final-quote',
          '/quote-register',
          '/almost-there',
          '/payment',
        ].includes(pathname),
        durationAction: isSubscription
          ? () => goToDuration('duration-of-cover')
          : () => goToDuration('duration'),
        displayCar:
          carIsSelected &&
          car.make.length &&
          car.model.length &&
          pathname !== '/quote-start' &&
          pathname !== '/choose-your-cover' &&
          pathname !== '/licence-switch',
      };
    }
    const canShowPrice = !!price && showPrice && !(hideSubsIndicativePrice && isSubscription);
    return {
      displayPrice:
        canShowPrice &&
        pathname !== '/start' &&
        pathname !== '/quote-start' &&
        pathname !== '/choose-your-cover' &&
        pathname !== '/licence-switch',
      displayDuration:
        duration &&
        duration.startDateTime &&
        duration.endDateTime &&
        pathname !== '/start' &&
        pathname !== '/quote-start' &&
        pathname !== '/choose-your-cover' &&
        pathname !== '/indicative-price' &&
        pathname !== '/duration-of-cover' &&
        pathname !== '/licence-switch',
      durationAction: !isSubscription ? () => goToDuration('duration-of-cover') : undefined,
      displayCar: carIsSelected && car.make && car.model,
    };
  };

  const { displayPrice, displayDuration, durationAction, displayCar } = getDisplays();
  const displayPreQuote =
    !displayPrice && !displayDuration && !displayCar && !displayDriverName && !displayOwnerName;

  return (
    <Container id='quote-container-quoteSummaryMain'>
      <TitleWrapper>
        <Title id='quote-title-quoteSummaryMain'>Quote Summary</Title>
      </TitleWrapper>
      <QuoteSummaryDetailsContainer>
        {displayDuration && (
          <QuoteSummaryDetail
            id='quote-component-quoteSummarydurationSection'
            title={!isSubscription ? 'Duration' : 'Monthly Subscription'}
            onClick={durationAction}
            showSeparator
          >
            <>
              <div id='quote-text-quoteSummaryDurationStart'>
                {isSubscription ? 'Starting: ' : 'From: '}
                {durationStartDate}
              </div>
              {!isSubscription && duration.endDateTime && (
                <div id='duration-end'>{durationEndDate}</div>
              )}
            </>
          </QuoteSummaryDetail>
        )}
        {displayCar && (
          <QuoteSummaryDetail
            id='quote-component-quoteSummaryCarSection'
            title='Car details'
            onClick={goToCar}
            showSeparator
          >
            <>
              <div id='quote-text-quoteSummaryCarMakeModel'>
                {car.make} {car.model}
              </div>
              <NumberPlate id='quote-container-quoteSummaryCarRegistration'>
                <div
                  id='quote-text-quoteSummaryCarRegistration'
                  style={{ padding: 2, fontSize: 12 }}
                >
                  {car.registrationNumber}
                </div>
              </NumberPlate>
            </>
          </QuoteSummaryDetail>
        )}
        {displayDriverName && (
          <QuoteSummaryDetail
            id='quote-component-quoteSummaryDriverSection'
            title='Driver details'
            onClick={(): void => goToAboutTheDriver(loggedIn)}
            showSeparator={productType !== 'tc'}
          >
            <div id='quote-text-quoteSummaryDriverDetails'>
              {capitaliseFirstLetter(driver.title)} {driver.firstName} {driver.lastName}
            </div>
          </QuoteSummaryDetail>
        )}
        {displayOwnerName && productType !== 'tc' && (
          <QuoteSummaryDetail
            id='quote-component-quoteSummaryOwnerSection'
            title='Owner details'
            onClick={(): void => goToOwnerDetails()}
          >
            <div id='quote-text-quoteSummaryOwnerDetails'>
              {owner.firstName} {owner.surname}
            </div>
          </QuoteSummaryDetail>
        )}
      </QuoteSummaryDetailsContainer>
      {displayPreQuote && (
        <LightParagraph id='quote-paragraph-quoteSummaryPreQuote'>
          You can use this section to track your quote progress and edit your details if needed.
        </LightParagraph>
      )}
      <PriceContainer id='quote-container-quoteSummaryPrice'>
        {displayPrice && (
          <>
            <PriceLabelWrapper
              discount={discount.displayDiscount(props)}
              crossSaleInitialPrice={!!crossSaleInitialPrice}
            >
              <PriceLabel id='quote-text-quoteSummaryPriceLabel'>
                {isFinalPrice ? 'Final price' : 'Current price'}
              </PriceLabel>
            </PriceLabelWrapper>
            <PriceValueWrapper>
              <PriceValue id='quote-text-quoteSummaryPriceValue' data-test='priceValue'>
                £{Number.parseFloat(price).toFixed(2)}
              </PriceValue>
            </PriceValueWrapper>
            {discount.displayDiscount(props) && (
              <HistoricPriceWrapper>
                <HistoricPriceValue
                  id='quote-text-quoteSummaryDiscountPriceValue'
                  data-test='priceValue'
                >
                  £{initialPrice}
                </HistoricPriceValue>
              </HistoricPriceWrapper>
            )}
            {crossSaleInitialPrice && (
              <HistoricPriceWrapper id='discounted-price'>
                <HistoricPriceValue
                  id='quote-text-quoteSummaryDiscountPriceValue'
                  data-test='priceValue'
                >
                  £{crossSaleInitialPrice}
                </HistoricPriceValue>
              </HistoricPriceWrapper>
            )}
          </>
        )}
      </PriceContainer>
    </Container>
  );
};

QuoteSummarySidebar.defaultProps = {
  showPrice: true,
  fullscreen: false,
  validDiscountCode: false,
};

export default QuoteSummarySidebar;

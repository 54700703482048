import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CognitoClient } from '@rentecarlo/node-amplify-client';
import { useLocation } from 'react-router-dom';
import { useOptimizelyFlag } from '@hooks';
import QuoteStep from '@templates/QuoteStep';
import Group from '@atoms/layout/form/Group';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import VipVeypal from '@assets/vip-veypal.svg';
import PrivacyPolicyInfo from '@atoms/PrivacyPolicyInfo';
import {
  Error,
  Info,
  LicenceSelector,
  PromptBox,
  OrSeparator,
  Panel,
  P,
} from '@rentecarlo/component-library';
import { setCancelToRepurchaseField } from '@reducer/quote/cancelToRepurchase.actions';
import { Container, Image, NewDriverInfoContainer, Text } from './styles';
import { ComponentProps } from './Start.container';

const Start: React.FC<ComponentProps> = ({
  firstName,
  licenceType,
  loggedIn,
  productType,
  login,
  resetQuote,
  submission,
  updateLicenceType,
  amplifyLoaded,
}) => {
  const allowAllNewDriver = useOptimizelyFlag('TEMP_ALLOW_ALL_NEW_DRIVER');

  useEffect(() => {
    resetQuote();
  }, [loggedIn, resetQuote]);

  const location = useLocation();
  const [licenseAutofill, setLicenseAutofill] = useState(licenceType);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search).get('licence-type');
    const validLicenceTypes = ['uk_prov', 'uk_manual'];
    const autofill =
      queryParams && validLicenceTypes.includes(queryParams) ? queryParams : licenceType;
    setLicenseAutofill(autofill);

    if (allowAllNewDriver.clientReady && !allowAllNewDriver.enabled) {
      if (productType === 'newdriver' && amplifyLoaded && !loggedIn) {
        // Can't rely on just the loggedIn value in the state (when users enter the QE) as
        // start renders before the init (login) saga finishes
        CognitoClient.getCurrentSession().catch(() => {
          login();
        });
      }
    }
  }, [
    amplifyLoaded,
    productType,
    loggedIn,
    login,
    location,
    licenceType,
    allowAllNewDriver.clientReady,
    allowAllNewDriver.enabled,
  ]);

  const dispatch = useDispatch();
  useEffect(() => {
    const previousSubscriptionId = new URLSearchParams(location.search).get(
      'previous_subscription_id',
    );
    if (previousSubscriptionId) {
      dispatch(setCancelToRepurchaseField('previousSubscriptionId', previousSubscriptionId));
    }
  }, [dispatch, location]);

  return !loggedIn ? (
    <>
      <PromptBox
        id='start-component-promptBox'
        title='Back for more?'
        buttonText='Sign in'
        marginTop='40px'
        text="If you've bought before, sign in for a quicker quote and more tailored price. 🚀"
        onClick={(): void => login()}
      />
      <OrSeparator />
      <QuoteStep
        id='start-component-quoteStepLoggedOut'
        paddingTop='24px'
        titleMarginBottom={productType === 'newdriver' ? '20px' : null}
        stepTitle='Start your quote...'
        nextAction={() => submission(licenceType, productType)}
        nextDisabled={
          licenceType === 'non_uk' ||
          (productType === 'newdriver' && !allowAllNewDriver.clientReady)
        }
        showButton
        nextLabel='Next'
        submitError={null}
      >
        {productType === 'newdriver' && (
          <NewDriverInfoContainer>
            <Panel>
              <P id='start-paragraph-quoteStep' fontSize={16}>
                Welcome to New Driver insurance, just answer a few short questions and we will be
                able to get a price for you...
              </P>
            </Panel>
          </NewDriverInfoContainer>
        )}
        <Group id='start-component-group'>
          <SectionTitle id='start-title-licenceType'>
            What is the driver's licence type?
          </SectionTitle>
          <LicenceSelector
            defaultType={licenseAutofill}
            selectedType={licenceType}
            displayedLicences={productType === 'newdriver' ? ['uk_manual'] : undefined}
            onChange={updateLicenceType}
          />
          {licenceType !== 'non_uk' ? (
            <Info id='start-text-foreignLicences'>
              We currently do not accept paper licences, Northern Irish licences or licences issued
              outside of Great Britain.
            </Info>
          ) : (
            <Error id='start-text-foreignLicencesError'>
              We currently do not accept paper licences, Northern Irish licences or licences issued
              outside of Great Britain.
            </Error>
          )}
        </Group>
        <PrivacyPolicyInfo />
      </QuoteStep>
    </>
  ) : (
    <QuoteStep
      id='start-component-quoteStepLoggedIn'
      stepTitle={`Welcome back ${firstName}!`}
      nextAction={() => submission(licenceType, productType)}
      showButton
      nextLabel='Next'
      submitError={null}
    >
      <Container>
        <Image src={VipVeypal} />
      </Container>
      <Text id='start-text-remembered'>
        To help speed up your quote, we've remembered your personal info from last time.
      </Text>
      {productType === 'newdriver' && licenceType === 'uk_prov' && (
        <Text id='start-text-eligibility'>
          To be eligible for New Driver insurance we will need to check that you have passed your
          driving test. You don't have to do anything, just click the next button below.
        </Text>
      )}
      <PrivacyPolicyInfo />
    </QuoteStep>
  );
};

export default Start;

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { getYourAgreementWithAbleUrl } from '@reselectors/files';
import { getOwnerName, getProductName } from '@reselectors/quote';
import { termsAndConditionsAccepted } from '@reducer/quote/payment.actions';
import { RootState } from '@redux/reducer';
import AlmostThere from './AlmostThere';

const mapStateToProps = (state: RootState) => ({
  isMobile: state.app.isMobile,
  yourAgreementWithAbleUrl: getYourAgreementWithAbleUrl(state),
  product: state.quote.product.productType,
  isDriverOwner: state.quote.driver.isDriverOwner,
  ownerName: getOwnerName(state),
  productName: getProductName(state),
  registrationNumber: state.quote.car.registrationNumber,
  excess: state.quote.importantInformation.excessSelected,
  hasInsurance: state.quote.car.hasInsurance,
  isSubscription: state.quote.product.isSubscription,
  hasBreakdownCover: state.quote.ancillary.breakdownCover,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  goToPayment: () => {
    dispatch(termsAndConditionsAccepted());
    dispatch(push('payment'));
  },
});

const AlmostThereContainer = connect(mapStateToProps, mapDispatchToProps)(AlmostThere);

export default AlmostThereContainer;

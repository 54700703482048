import deepUpdate from '@services/deepUpdate';
import {
  types as cancelToRepurchasetypes,
  CancelToRepurchaseActions,
} from './cancelToRepurchase.actions';
import { types as quoteTypes, QuoteActions } from './actions';

export interface CancelToRepurchase {
  previousSubscriptionId: string | null;
  creditAmount: string | null;
}

export const initialState: CancelToRepurchase = {
  previousSubscriptionId: null,
  creditAmount: null,
};

export type CancelToRepurchaseActionTypes = CancelToRepurchaseActions | QuoteActions;

export default function cancelToRepurchase(
  state = initialState,
  action = {} as CancelToRepurchaseActionTypes,
): CancelToRepurchase {
  switch (action.type) {
    case cancelToRepurchasetypes.SET_CANCEL_TO_REPURCHASE_FIELD:
      return deepUpdate(state, action.field, action.value);
    case quoteTypes.RESET_QUOTE:
      return initialState;
    default:
      return state;
  }
}

type OptimizelyProduct = 'web' | 'flags';

export const decideOptimizelyProduct = (): OptimizelyProduct => {
  let optimizelyProduct: OptimizelyProduct;

  const storedProduct = sessionStorage.getItem('optimizelyProduct');

  if (storedProduct && ['web', 'flags'].includes(storedProduct)) {
    optimizelyProduct = storedProduct as OptimizelyProduct;
  } else {
    optimizelyProduct = Math.random() > 0.5 ? 'web' : 'flags';
    sessionStorage.setItem('optimizelyProduct', optimizelyProduct);
  }

  return optimizelyProduct;
};

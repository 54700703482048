import { OptimizelyOverrideAttributes } from '@redux/reselectors/optimizely';
import { decideOptimizelyProduct } from '@utils/optimizelyProduct';
import { optimizelyClient } from './clients/optimizely';

export default function sendOptimizelyEvent(
  eventKey: string,
  attributes: OptimizelyOverrideAttributes,
): void {
  const optimizelyProduct = decideOptimizelyProduct();

  optimizelyClient.track(eventKey, attributes.user_identity, {
    ...attributes,
    optimizely_product: optimizelyProduct,
  });

  window.optimizely = window.optimizely || [];
  window.optimizely.push({
    type: 'user',
    attributes: { ...attributes, optimizely_product: optimizelyProduct },
  });

  window.optimizely.push({
    type: 'event',
    eventName: eventKey,
  });
}

import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { reduxForm } from 'redux-form';
import moment, { Moment } from 'moment';

import { Dispatch } from 'redux';

import { RootState } from '@redux/reducer';
import { LicenceTypes } from '@reducer/quote/licence';

import { setLicenceField, changeLicenceType } from '@reducer/quote/licence.actions';
import { setCustomerField } from '@reducer/account/customer.actions';
import { saveAndGoTo } from '@reducer/quote/save.actions';

import { NLiCassiePQuoteFSelector } from '@reselectors/account';
import { setProduct } from '@redux/reducer/quote/product.actions';
import UpgradeAccountOptions from './UpgradeAccountOptions';

export enum SelectedOptions {
  upgrade = 'upgrade',
  continue = 'continue',
}

export interface Values {
  selectedOption: SelectedOptions | null;
  statedTestPassDate: Moment;
  loggedIn: boolean;
  upgradeFromStart: boolean;
  productType: string;
}

interface StateProps {
  loggedIn: boolean;
  NLicassiePQuoteF: boolean;
  productType: string;
}

interface DispatchProps {
  continueQuote: (values: Values) => void;
}

export interface ComponentProps extends StateProps, DispatchProps {
  valid: boolean;
  handleSubmit: (arg0: (values: Values) => void) => void;
}

export const redirectUser = (dispatch: Dispatch, values: Values): void => {
  if (values.selectedOption === SelectedOptions.upgrade) {
    if (moment().diff(values.statedTestPassDate, 'days') > 90) {
      dispatch(push('/licence-change/error'));
    } else if (values.loggedIn) {
      dispatch(setCustomerField('statedTestPassDate', values.statedTestPassDate));
      dispatch(push('/licence-change/success'));
    } else {
      dispatch(changeLicenceType(LicenceTypes.uk_manual));
      dispatch(setLicenceField('fullLicenceConfirmed', true));
      dispatch(setLicenceField('fullLicenceDate', values.statedTestPassDate));

      dispatch(push('/important-information'));
    }
  } else if (values.selectedOption === SelectedOptions.continue) {
    dispatch(changeLicenceType(LicenceTypes.uk_prov));
    if (values.productType === 'newdriver') {
      dispatch(setProduct('ldp'));
    }

    dispatch(saveAndGoTo('/start'));
  }
};

const mapStateToProps = (state: RootState): StateProps => {
  const {
    account: {
      login: { loggedIn },
    },
    quote: { product },
  } = state;
  return {
    loggedIn,
    NLicassiePQuoteF: NLiCassiePQuoteFSelector(state),
    productType: product.productType,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  continueQuote: (values: Values) => {
    redirectUser(dispatch, values);
  },
});

const UpgradeAccountContainer = connect(mapStateToProps, mapDispatchToProps)(UpgradeAccountOptions);

export default reduxForm({
  form: 'upgradeAccountForm',
})(UpgradeAccountContainer);

import Debug from 'debug';
import React, { useEffect, useState } from 'react';
import { InjectedFormProps, Field } from 'redux-form';
import styled from 'styled-components';

import Group from '@atoms/layout/form/Group';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import TextInput from '@atoms/inputs/TextInputField';
import InfoTag from '@atoms/paragraphs/InfoTag';
import QuoteStep from '@templates/QuoteStep';
import EmailAlreadyExistsModal from '@organisms/modals/EmailAlreadyExistsModal';
import accountApi from '@services/api/customer';
import ReduxFieldValidation from '@services/formValidation';

import UpgradeLicenceBackground from '@assets/prompt-box-background-uml.svg';
import PlateIcon from '@assets/p-plate-v2.svg';
import { PromptBox } from '@rentecarlo/component-library';

import {
  useAppDispatch,
  useAppSelector,
  useOptimizelyFlag,
  useOptimizelyFlagVariation,
  useOptimizelyTrack,
} from '@hooks';
import { goToUpgrade } from '@reducer/quote/licence.actions';
import { setUserField } from '@reducer/quote/user.actions';

import PostcodeAndDoB from './PostcodeAndDob';
import AboutCar from './AboutCar';
import { ComponentProps } from './QuoteStart.container';

const InfoTagContainer = styled.div`
  margin: -7px 16px 0 16px;
`;

const debug = Debug('veygo:pages:quoteStart');

const getTextAttributes = (hideSubsIndicativePrice: boolean, indicativePriceRemoved: boolean) => {
  if (indicativePriceRemoved) {
    return {
      titleText: 'Tell us about you',
      subTitleText:
        'Please provide a few essential details so we can tailor our services just for you.',
    };
  }
  if (hideSubsIndicativePrice) {
    return {
      titleText: '',
      subTitleText: '',
    };
  }
  return {
    titleText: 'Price incoming...',
    subTitleText:
      "Just complete the below and we'll have your guide price waiting on the next page.",
  };
};

const QuoteStart: React.FC<ComponentProps & InjectedFormProps> = ({
  licenceType,
  productType,
  setDriverOwner,
  setProduct,
  submission,
  submitAddressAndDob,
  // formErrors isn't injected by redux-form, but it's a part of it
  formErrors,
  // Redux-Form injected props
  formValues,
  handleSubmit,
  initialize,
  submitFailed,
  valid,
  clearEmailField,
  email,
}) => {
  // Selectors and Dispatch
  const dispatch = useAppDispatch();

  const birthdate = useAppSelector((state) => state.quote.driver.birthdate);
  const car = useAppSelector((state) => state.lookup.car);
  const haveCar = useAppSelector((state) => Boolean(state.lookup.car?.make));
  const isLoggedIn = useAppSelector((state) => state.account.login.loggedIn);
  const postcode = useAppSelector((state) => state.account.customer.postcode);

  const [showEmailExistsModal, toggleEmailExistsModal] = useState(false);

  const hideSubsIndicativePrice = useOptimizelyFlag('TEMP_HIDE_INDICATIVE_SUBS').enabled;
  const indicativePriceRemoved = useOptimizelyFlagVariation('durationEnabled');
  const redesignedUMLEnabled = useOptimizelyFlag('TEMP_REDESIGNED_UML').enabled;
  const showEmailInput = useOptimizelyFlag('TEMP_EMAIL_ON_QUOTE_START').enabled;
  const { titleText, subTitleText } = getTextAttributes(
    hideSubsIndicativePrice,
    indicativePriceRemoved,
  );
  const checkEmailExists = async (): Promise<void> => {
    try {
      if (!formErrors.email) {
        await accountApi.checkEmail(email);

        // Hide any onscreen options/keyboards if user has focused another field after inputting email
        (document.activeElement as HTMLElement).blur();

        toggleEmailExistsModal(true);
      }
    } catch (exception) {
      // Email wasn't found
    }
  };

  const sendEvent = useOptimizelyTrack();

  useEffect(() => {
    if (isLoggedIn) {
      const values = Object.assign(formValues, { postcode, birthdate });
      debug('initializing form with values', values);
      initialize(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, postcode, birthdate]);

  return (
    <>
      {isLoggedIn &&
        !redesignedUMLEnabled &&
        (productType === 'ldp' || (productType === 'newdriver' && licenceType === 'uk_prov')) && (
          <PromptBox
            id='upgradeLicencePromptbox'
            title='Passed your test?'
            text='If you’re no longer learning, congrats! 🎉 You need to update your licence.'
            buttonText='Change to full licence'
            icon={PlateIcon}
            marginTop='48px'
            marginBottom='0px'
            textFontSize='16px'
            background={UpgradeLicenceBackground}
            onClick={() => dispatch(goToUpgrade())}
          />
        )}
      <QuoteStep
        displaySummaryFooter={false}
        form
        formErrors={formErrors}
        nextAction={handleSubmit((values: Record<string, unknown>) => {
          const valuesWithCar = {
            ...values,
            car,
          };
          sendEvent('save_product_selected');
          submission(valuesWithCar, productType);
        })}
        nextLabel='Next'
        nextDisabled={!haveCar}
        stepTitle={titleText}
        stepSubtitle={subTitleText}
        submitError={submitFailed && !valid}
        showButton={haveCar}
      >
        <form
          onSubmit={handleSubmit((values: Record<string, unknown>) => {
            submission(values, productType);
          })}
        />
        <PostcodeAndDoB />
        {showEmailInput && !isLoggedIn && (
          <Group>
            <SectionTitle id='aboutYou-title-email'>
              What is the driver's email address?
            </SectionTitle>
            <Field
              id='quoteStartForm-field-email'
              name='email'
              type='email'
              placeholder='Email address'
              maxLength={254}
              component={TextInput}
              validate={[ReduxFieldValidation.isRequired, ReduxFieldValidation.isEmail]}
              disabled={isLoggedIn}
              onBlur={() => checkEmailExists()}
            />
            <InfoTagContainer>
              <InfoTag
                id='quoteStartForm-text-infoTag'
                text="This email address is really important - it's where we'll send your policy documents
                and also the magic link to recover your account if the password is forgotten. We'll
                send marketing updates here too. You can change your preferences at any time in your
                account or by clicking the unsubscribe link in marketing emails."
              />
            </InfoTagContainer>
          </Group>
        )}
        <AboutCar
          toggleStatus={(status: boolean) => dispatch(setUserField('status', status))}
          setProduct={setProduct}
          setDriverOwner={setDriverOwner}
          submitFailed={submitFailed}
          onModalOpen={handleSubmit((values: Record<string, unknown>) => {
            submitAddressAndDob(values, licenceType);
          })}
          licenceType={licenceType}
        />
      </QuoteStep>
      <EmailAlreadyExistsModal
        visible={showEmailExistsModal}
        close={() => {
          clearEmailField();
          toggleEmailExistsModal(false);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }}
        email={email}
      />
    </>
  );
};

export default QuoteStart;

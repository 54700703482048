import { SetStateFieldActionCreator } from '@utils/types';
import { CancelToRepurchase } from './cancelToRepurchase';

export enum types {
  SET_CANCEL_TO_REPURCHASE_FIELD = 'SET_CANCEL_TO_REPURCHASE_FIELD',
}

type SetCancelToRepurchaseField = ReturnType<typeof setCancelToRepurchaseField>;

export type CancelToRepurchaseActions = SetCancelToRepurchaseField;

export const setCancelToRepurchaseField: SetStateFieldActionCreator<
  CancelToRepurchase,
  typeof types.SET_CANCEL_TO_REPURCHASE_FIELD
> = (field, value) => ({
  type: types.SET_CANCEL_TO_REPURCHASE_FIELD,
  field,
  value,
});

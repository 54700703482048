import React, { useState } from 'react';
import { Field } from 'redux-form';
import styled, { css } from 'styled-components';

import Group from '@atoms/layout/form/Group';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import ReduxFieldValidation from '@services/formValidation';
import DateField from '@atoms/inputs/DateField';
import QuoteStep from '@templates/QuoteStep';
import colors from '@config/colors';
import OptionButton from '@atoms/buttons/OptionButton';
import size from '@config/size';

import { ComponentProps, Values, SelectedOptions } from './UpgradeAccountOptions.container';

const ExplanationTextContainer = styled.div`
  flex: 1;
  margin-bottom: 32px;
`;

const ExplanationText = styled.span`
  color: ${colors.outerSpace};
  font-family: 'proxima-soft';
  font-size: 16px;
  line-height: 19px;
  text-align: justify;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 72px;
`;

const OptionsHeader = styled.span`
  color: ${colors.outerSpace};
  font-family: 'gotham-rounded-book';
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
  margin-bottom: 24px;
  justify-self: center;
`;

const OptionsHeaderBold = styled.span`
  font-weight: 700;
`;

const OptionsButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  ${css`
    @media (max-width: ${size.desktopWidth}) {
      flex-direction: column;
    }
  `}
`;

const OptionsButtonContainer = styled.div`
  flex: 1;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    position: relative;
    left: -192px;
  }
  &:last-child {
    margin-left: 16px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      left: 0px;
    }
  }
  @media (max-width: ${size.desktopWidth}) {
    &:last-child {
      margin-left: 0px;
      margin-top: 16px;
    }
  }
`;

const DisclaimerTextContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const DisclaimerText = styled.span`
  font-family: 'proxima-soft';
  color: ${colors.outerSpace};
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;

const BoldItalic = styled.span`
  font-weight: bold;
  font-style: italic;
`;

const TestPassDateContainer = styled.div`
  margin-top: 40px;
`;

const UpgradeAccountOptions: React.FC<ComponentProps> = ({
  valid,
  handleSubmit,
  continueQuote,
  NLicassiePQuoteF,
  loggedIn,
  productType,
}) => {
  const [selectedOption, setselectedOption] = useState<SelectedOptions | null>(null);

  return (
    <QuoteStep
      id='licenceChange-component-quoteStep'
      stepTitle='Hang on...'
      stepSubtitle={loggedIn ? undefined : `Something doesn't look quite right.`}
      includeStepPrefix={false}
      nextLabel='Continue'
      nextDisabled={!valid || !selectedOption}
      displaySummaryFooter={false}
      nextAction={handleSubmit((values: Values) => {
        const { statedTestPassDate } = values;
        continueQuote({
          statedTestPassDate,
          selectedOption,
          loggedIn,
          productType,
        });
      })}
    >
      <ExplanationTextContainer>
        <ExplanationText id='licenceChange-text-explanationText'>
          It looks like you've started a quote that{' '}
          <strong id='licenceChange-text-explanationTextStrongRequires'>
            requires a full licence
          </strong>
          , but your account has a{' '}
          <strong id='licenceChange-text-explanationTextStrongProvisional'>
            provisional driving licence
          </strong>
          , which only allows you to purchase our{' '}
          <strong id='licenceChange-text-explanationTextStrongLeaner'>
            learner driver insurance
          </strong>
        </ExplanationText>
      </ExplanationTextContainer>
      <OptionsContainer>
        <OptionsHeader id='licenceChange-text-options'>
          You now have{' '}
          <OptionsHeaderBold id='licenceChange-text-optionsBold'>two options...</OptionsHeaderBold>
        </OptionsHeader>
        <OptionsButtonGroup>
          <OptionsButtonContainer>
            <OptionButton
              id='licenceChange-button-confirmUpgrade'
              header={NLicassiePQuoteF ? 'Confirm your pass date' : 'Upgrade your account'}
              body={[
                'This will ',
                <BoldItalic id='licenceChange-text-boldChange'>change</BoldItalic>,
                ' your licence type in your Veygo account from a provisional licence to a full GB licence.',
              ]}
              footer='Only select this if you have passed your test and no longer have a provisional.'
              selected={selectedOption === SelectedOptions.upgrade}
              onClick={() => setselectedOption(SelectedOptions.upgrade)}
            />
          </OptionsButtonContainer>
          <OptionsButtonContainer>
            <OptionButton
              id='licenceChange-button-continue'
              header={
                NLicassiePQuoteF ? 'Switch to learner driver' : 'Continue as a learner driver'
              }
              body={[
                'This will ',
                <BoldItalic id='licenceChange-text-continueBold'>keep</BoldItalic>,
                ' your licence type as a provisional licence until you pass your test.',
              ]}
              footer='You will need to reconfirm some details in your current quote.'
              selected={selectedOption === SelectedOptions.continue}
              onClick={() => setselectedOption(SelectedOptions.continue)}
            />
          </OptionsButtonContainer>
        </OptionsButtonGroup>
      </OptionsContainer>
      {selectedOption === 'upgrade' && (
        <TestPassDateContainer>
          <Group>
            <SectionTitle id='licenceChange-title-passDate'>
              When did you pass your test?
            </SectionTitle>
            <Field
              name='statedTestPassDate'
              component={DateField}
              validate={[ReduxFieldValidation.required, ReduxFieldValidation.isDateInThePast]}
            />
          </Group>
        </TestPassDateContainer>
      )}
      <DisclaimerTextContainer>
        <DisclaimerText id='licenceChange-text-disclaimerText'>
          I confirm by pressing continue that I am logging my correct licence type and at the point
          of claim, if this is incorrect, my policy will be void.
        </DisclaimerText>
      </DisclaimerTextContainer>
    </QuoteStep>
  );
};

export default UpgradeAccountOptions;

import { useSelector } from 'react-redux';
import { useDecision, OptimizelyDecision } from '@optimizely/react-sdk';

import { optimizelyOverrideAttributes } from '@reselectors/optimizely';
import { decideOptimizelyProduct } from '@utils/optimizelyProduct';

export interface OptimizelyFlag extends OptimizelyDecision {
  clientReady: boolean;
}

const useOptimizelyFlag = (featureFlag: string): OptimizelyFlag => {
  const overrideAttributes = useSelector(optimizelyOverrideAttributes);
  const optimizelyProduct = decideOptimizelyProduct();

  const [decision, clientReady] = useDecision(
    featureFlag,
    {},
    {
      overrideUserId: overrideAttributes.user_identity,
      overrideAttributes: { ...overrideAttributes, optimizely_product: optimizelyProduct },
    },
  );
  return { ...decision, clientReady };
};

const flagVariations = {
  durationEnabled: {
    flagName: 'TEMP_VARIATION_VEY015',
    variationKeys: ['on', 'DURATION_ON_UP_SELL_OFF'],
  },
  upSellEnabled: {
    flagName: 'TEMP_VARIATION_VEY015',
    variationKeys: ['on', 'DURATION_OFF_UP_SELL_ON'],
  },
};

export const useOptimizelyFlagVariation = (feature: keyof typeof flagVariations): boolean => {
  const flag = useOptimizelyFlag(flagVariations[feature].flagName);
  return flag.enabled && flagVariations[feature].variationKeys.includes(flag.variationKey || '');
};

export default useOptimizelyFlag;

import { Moment } from 'moment';
import { SetStateFieldActionCreator } from '@utils/types';
import { LicenceTypes } from './licence';
import { User } from './user';

export enum types {
  SET_USER_FIELD = 'SET_USER_FIELD',
  PREFILL_DRIVER = 'PREFILL_DRIVER',
  PREFILL_DRIVER_ON_EDIT = 'PREFILL_DRIVER_ON_EDIT',
  PREFILL_OWNER = 'PREFILL_OWNER',
  PREFILL_OWNER_ON_EDIT = 'PREFILL_OWNER_ON_EDIT',
  PREFILL_CONNECTION = 'PREFILL_CONNECTION',
}

type SetUserField = ReturnType<typeof setUserField>;

export interface PrefillDriverAction {
  type: typeof types.PREFILL_DRIVER;
  extra: {
    accountData: boolean;
  };
}
export interface PrefillDriver extends PrefillDriverAction {
  data: {
    customer: {
      ncb: {
        haveNCB: boolean;
        level: number;
      };
      licence: {
        country: string;
        number: string;
        fullLicenceConfirmed: boolean;
        fullLicenceDate: Moment;
        type: LicenceTypes;
      };
    };
  };
}

export interface PrefillDriverOnExitAction {
  type: typeof types.PREFILL_DRIVER_ON_EDIT;
  extra: {
    accountData: boolean;
  };
}

export interface PrefillDriverOnExit extends PrefillDriverOnExitAction {
  data: {
    customer: {
      ncb: {
        haveNCB: boolean;
        level: number;
      };
      licence: {
        country: string;
        number: string;
        fullLicenceConfirmed: boolean;
        fullLicenceDate: Moment;
        type: LicenceTypes;
      };
    };
  };
}

export interface PrefillOwner {
  type: typeof types.PREFILL_OWNER;
  extra: {
    accountData: boolean;
  };
}

export interface PrefillOwnerOnEdit {
  type: typeof types.PREFILL_OWNER_ON_EDIT;
  extra: {
    accountData: boolean;
  };
}

export interface PrefillActionConnection {
  type: typeof types.PREFILL_CONNECTION;
  extra: {
    accountData: boolean;
    preferredCar: boolean;
  };
}

export interface PrefillConnection extends PrefillActionConnection {
  preferredCar: {
    relationship: string;
  };
  data: {
    customer: {
      ncb: {
        haveNCB: boolean;
        level: number;
      };
      licence: {
        country: string;
        number: string;
        fullLicenceConfirmed: boolean;
        fullLicenceDate: Moment;
        type: LicenceTypes;
      };
    };
  };
}

export type UserActions =
  | SetUserField
  | PrefillDriver
  | PrefillDriverOnExit
  | PrefillOwner
  | PrefillOwnerOnEdit
  | PrefillConnection;

// Actions
export const setUserField: SetStateFieldActionCreator<User, typeof types.SET_USER_FIELD> = (
  field,
  value,
) => ({
  type: types.SET_USER_FIELD,
  field,
  value,
});

export const prefillDriver = (): PrefillDriverAction => ({
  type: types.PREFILL_DRIVER,
  extra: {
    accountData: true,
  },
});

export const prefillDriverOnEdit = (): PrefillDriverOnExitAction => ({
  type: types.PREFILL_DRIVER_ON_EDIT,
  extra: {
    accountData: true,
  },
});

export const prefillOwner = (): PrefillOwner => ({
  type: types.PREFILL_OWNER,
  extra: {
    accountData: true,
  },
});

export const prefillOwnerOnEdit = (): PrefillOwnerOnEdit => ({
  type: types.PREFILL_OWNER_ON_EDIT,
  extra: {
    accountData: true,
  },
});

export const prefillConnection = (): PrefillActionConnection => ({
  type: types.PREFILL_CONNECTION,
  extra: {
    accountData: true,
    preferredCar: true,
  },
});

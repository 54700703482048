import React from 'react';

import styled from 'styled-components';

import { Card } from '@rentecarlo/component-library';

const Wrapper = styled.div`
  width: 100%;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  width: 100%;

  padding: 16px;
`;

const CardBanner = styled.div`
  background-color: ${({ theme }) => theme.successSecondary};
  width: 100%;

  border-radius: 8px 8px 0 0;

  display: flex;
  justify-content: center;

  color: #fff;
  font-weight: 600;

  padding: 2px 0;
`;

const CardText = styled.p`
  font-family: ${({ theme }) => theme.standard};
  font-size: 16px;
  line-height: 23px;
  font-weight: 700;
`;

const CardImage = styled.img`
  height: 60px;
  width: auto;
`;

const CardContent = styled.div`
  padding: 0 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Spacer = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.fieldSecondary};
  margin-bottom: 16px;
`;

interface InfoCardProps {
  id: string;
  image: string;
  altText: string;
  text: string;
  bannerText?: string;
  children?: React.ReactNode;
}

const StepCard: React.FC<InfoCardProps> = ({ id, image, altText, text, bannerText, children }) => (
  <Wrapper>
    <Card id={id}>
      {bannerText && <CardBanner id={`${id}-banner`}>{bannerText}</CardBanner>}
      <CardContainer>
        <CardImage src={image} alt={altText} />
        <CardText id={`${id}-text`}>{text}</CardText>
      </CardContainer>
      {children && (
        <CardContent>
          <Spacer />
          {children}
        </CardContent>
      )}
    </Card>
  </Wrapper>
);

export default StepCard;

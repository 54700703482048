import { connect } from 'react-redux';
import { Dispatch, UnknownAction } from 'redux';
import { push } from 'connected-react-router';
import { resetDuration } from '@reducer/quote/duration.actions';
import { saveAndGoTo } from '@reducer/quote/save.actions';
import { isForbiddenDuration } from '@services/date';
import { RootState } from '@redux/reducer';
import { PaymentMethod } from '@utils/types';

import Duration from './Duration';

const resetAndGoToDuration = (dispatch: Dispatch) => {
  dispatch(resetDuration());
  dispatch((push('duration-of-cover') as unknown) as UnknownAction);
};

const mapStateToProps = (state: RootState) => {
  const {
    quote: {
      duration: { endDateTime, endChoiceSelected, startDateTime },
      indicativePrice: { loading },
      product: { productType, paymentMethod },
      driver: { birthdate },
    },
  } = state;

  const disableQuickDateSelectionButton = isForbiddenDuration(startDateTime, endDateTime) ?? false;

  return {
    productType,
    paymentMethod: paymentMethod as PaymentMethod,
    loading,
    disableQuickDateSelectionButton,
    endChoiceSelectedValue: endChoiceSelected && endChoiceSelected.value,
    birthdate,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetAndGoToDuration: () => resetAndGoToDuration(dispatch),
  submit: () => {
    dispatch(saveAndGoTo('important-information', true));
  },
});

const DurationContainer = connect(mapStateToProps, mapDispatchToProps)(Duration);

export default DurationContainer;
